document.addEventListener("DOMContentLoaded", function () {
  const overlay = document.getElementById("overlay");
  const menuBtn = document.getElementById("show_categories_btn");
  const scMegamenuWrap = document.querySelector(".sc-megamenu-wrap");
  const menu = document.querySelector(".sc-megamenu");
  const sidebarCloseBtn = document.querySelector(".sidebar-header-close");
  const html = document.querySelector("html");
  let isMenuOpen = false;

  function addOrRemoveClass(element, className, add) {
    if (add) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }

  function toggleMenu(add) {
    [overlay, scMegamenuWrap, menu].forEach((el) => addOrRemoveClass(el, "active", add));
    addOrRemoveClass(html, "lock", add);
  }

  if (menuBtn) {
    menuBtn.addEventListener("click", () => {
      isMenuOpen = !isMenuOpen;
      toggleMenu(isMenuOpen);
    });
  }

  if (sidebarCloseBtn) {
    sidebarCloseBtn.addEventListener("click", (event) => {
      event.preventDefault();

      isMenuOpen = false;
      toggleMenu(isMenuOpen);
    });
  }

  if (scMegamenuWrap) {
    scMegamenuWrap.addEventListener("click", (event) => {
      if (event.target === scMegamenuWrap) {
        isMenuOpen = false;
        toggleMenu(isMenuOpen);
      }
    });
  }

  if (overlay) {
    overlay.addEventListener("click", (event) => {
      if (event.target === overlay) {
        isMenuOpen = false;
        toggleMenu(isMenuOpen);
      }
    });
  }
});