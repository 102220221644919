/* Burger menu = START */
document.addEventListener("DOMContentLoaded", function () {
  const burgerBtn = document.querySelector(".header__burger-btn");
  const burgerMenu = document.querySelector(".header__burger-menu");
  const burgerClose = document.querySelector(".header__burger-close");
  const burgerMenuWrap = document.querySelector(".header__burger-menu-wrap");
  const htmlTag = document.querySelector("html");

  function addOrRemoveClass(element, className, add) {
    if (add) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }

  burgerBtn.addEventListener("click", () => {
    addOrRemoveClass(burgerMenu, "active", true);
    addOrRemoveClass(burgerMenuWrap, "active", true);
    addOrRemoveClass(htmlTag, "lock", true);
  });

  burgerClose.addEventListener("click", (e) => {
    e.preventDefault();

    addOrRemoveClass(burgerMenuWrap, "active", false);
    addOrRemoveClass(burgerMenu, "active", false);
    addOrRemoveClass(htmlTag, "lock", false);
  });

  htmlTag.addEventListener("click", function (e) {
    if (e.target.matches(".header__burger-menu-wrap.active")) {
      [burgerMenuWrap, burgerMenu].forEach((el) =>
        addOrRemoveClass(el, "active", false)
      );
      addOrRemoveClass(htmlTag, "lock", false);
    }
  });
});
/* Burger menu = END */