/* Title move */
$(document).ready(function () {
  // if ($(window).width() > 1023.98) {
  $(window).scroll(function () {
    var scrollPos = $(this).scrollTop() / 4;
    $(".title_scroll").css({
      transform: "translateX(" + -scrollPos + "px)"
    });
  });
  // }
});

document.addEventListener('DOMContentLoaded', () => {
  // Initialize AOS with default parameters
  AOS.init({
    useClassNames: true,
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    anchorPlacement: 'top-center',
    disable: false,
  });

  // Function to reinitialize AOS
  const reinitAOS = () => {
    AOS.refreshHard();
  };

  // Initialize Intersection Observer
  const initAOSObserver = () => {
    // Get all elements with the data-aos attribute
    const aosElements = document.querySelectorAll('[data-aos]');

    // Create Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add a class to start the animation
          entry.target.classList.add('aos-animate');
          // Stop watching this element after the animation starts
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1, // Set the animation trigger threshold
    });

    // Observe each element
    aosElements.forEach(element => {
      observer.observe(element);
    });
  };

  // Check that all images are loaded
  window.addEventListener('load', () => {
    // Starting animation for the first blocks
    const aosElements = document.querySelectorAll('.aos-element');
    aosElements.forEach(element => {
      element.classList.add('aos-animate');
    });

    initAOSObserver();
    reinitAOS();
  });

  // Process dynamically added elements (if any)
  new MutationObserver((mutations) => {
    mutations.forEach(mutation => {
      if (mutation.addedNodes.length) {
        AOS.refreshHard();
      }
    });
  }).observe(document.body, { childList: true, subtree: true });
});