const icons = document.querySelectorAll('.icon-parallax'); // for icons
const images = document.querySelectorAll('.image-parallax'); // for images

function handleMouseMoveParallax(event) {
  if (window.innerWidth > 991.98) {
    const xAxis = (window.innerWidth / 2 - event.pageX) / 100;
    const yAxis = -(window.innerHeight / 2 - event.pageY) / 100;

    icons.forEach((icon, index) => {
      setTimeout(() => {
        icon.style.transform = `translate(${xAxis}px, ${yAxis}px)`;
      }, index * 50);
    });
  }
}

function handleScrollParallax() {
  if (window.innerWidth > 991.98) {
    const scrollY = window.scrollY;

    images.forEach((image, index) => {
      setTimeout(() => {
        image.style.transform = `translateY(${scrollY / 3}px)`;
      }, index * 50);
    });
  }
}

window.addEventListener('mousemove', handleMouseMoveParallax);
window.addEventListener('scroll', handleScrollParallax);