/* Hide tooltip on hover and mouseleave = START */
document.addEventListener("DOMContentLoaded", function () {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"], [data-toggle="tooltip"]'
  );

  tooltipTriggerList.forEach(function (buttonTooltip) {
    const tooltip = new bootstrap.Tooltip(buttonTooltip);

    buttonTooltip.addEventListener("mouseleave", function () {
      tooltip.hide();
    });

    buttonTooltip.addEventListener("focus", function () {
      tooltip.hide();
    });
  });
});
/* Hide tooltip on hover and mouseleave = END */